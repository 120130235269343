import React, { useState } from 'react'
import { Box, Container, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import { useGeo } from '../../../api/queries'

export const patronPackageUrl = '/ticketing/patron-package/'
export const sponsorPackageUrl = '/ticketing/sponsor-package/'

export const PackageSubheading = (props) => (
  <Box as={'p'} fontWeight={'bold'} mt={8} mb={1} {...props} />
)

export function WatchPartyText() {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <li>
      Watch party with up to 6 patrons.{' '}
      <Tooltip
        placement={'auto'}
        hasArrow={true}
        isOpen={showTooltip}
        label={
          <>
            Watch the stream together! Create private video chat rooms of up to
            6 participants right alongside the stream. Watch the event and
            communicate with each other at the same time! <br />
            <br />
            Note that each participant needs to have purchased the patron
            package to join.
          </>
        }
      >
        <Box
          as={'span'}
          color={'gold'}
          onMouseOver={() => setShowTooltip(true)}
          onMouseOut={() => setShowTooltip(false)}
          onClick={() => setShowTooltip(true)}
        >
          What's this?
        </Box>
      </Tooltip>
    </li>
  )
}

export function PatronPackageItems() {
  return (
    <>
      <PackageSubheading>Package includes:</PackageSubheading>
      <ul>
        <li>
          <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Karma: A Yogi’s Guide to Crafting Your Destiny
          </span>{' '}
          - Autographed by Sadhguru (limited copies available)
        </li>
        <li>Access to 5 consecutive events</li>
        <li>
          <strong>Karma</strong> T-shirt
        </li>
        <li>
          30 days access to the <strong>event recording</strong>
        </li>
      </ul>
    </>
  )
}

export function SponsorPackageItems() {
  const {
    geo: { country },
    isSuccess,
  } = useGeo()
  const isCanada = country === 'CA'
  return (
    <>
      <PackageSubheading>Package includes:</PackageSubheading>
      <ul>
        <li>
          3 Hardcover Copies of{' '}
          <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Karma: A Yogi’s Guide to Crafting Your Destiny
          </span>
        </li>
        <li>Access to 3 consecutive events</li>
        <li>
          15 days access to the <strong>event recording</strong>
        </li>

        <Skeleton isLoaded={isSuccess}>
          <li>
            <strong>Special option:</strong> Purchase the <strong>Karma</strong>{' '}
            T-shirt for an additional {isCanada ? 'CA-$7' : '$5'}. (T-shirt
            valued at {isCanada ? 'CA-$25' : '$20'})
          </li>
        </Skeleton>
      </ul>
    </>
  )
}

export const SpacedHeading = (props) => (
  <Text
    textTransform={'uppercase'}
    fontSize={'2xl'}
    letterSpacing={'0.08em'}
    {...props}
  />
)

export function Separator() {
  return (
    <Container maxW={'container.md'}>
      <Box borderBottom={'solid 2px'} borderColor={'blue'} mb={4} />
    </Container>
  )
}
